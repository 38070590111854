.productContainer {
  height: auto;
  display: flex;
  flex-direction: row-reverse;
}

.productImage {
  padding-right: 1em;
}

.productInfo {
  flex: 1;
  padding-top: 0px;
}

.combined-header {
  margin-top: 0.5%;
  color: rgba(0, 0, 0, 0.87);
  display: block;
  font-size: 15px;
  font-weight: bold;
  padding: 0.2% 2% 0.2% 0.5%;
}
/* 
.combinedProductInfo{
 
} */

.item {
  min-width: 15%;
  max-width: 31%;
  float: left;
  color: rgba(0, 0, 0, 0.87);
  background-color: rgb(255, 255, 255);
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  box-sizing: border-box;
  font-size: small;
  line-height: 1.5em;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
  border-radius: 2px;
  margin: 0.5%;
  position: relative;
  padding: 0.2% 1% 0.2% 1%;
}

.product-label {
  margin-right: 1em;
}

ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.card-header {
  background: red;
  max-width: 80%;
}

img {
  object-fit: contain;
  width: 80px;
  height: auto;
  filter: contrast(1.25);
  image-rendering: -webkit-optimize-contrast;
}

.MuiCardHeader-action {
  flex: 0 0 auto !important;
  align-self: center !important;
  margin-top: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 0 !important;
}
