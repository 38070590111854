.sidenavContent {
  padding: 1em;
  background-color: #fff;
}

.container {
  position: absolute;
  left: 26%;
  right: 0;
  top: 0;
  bottom: 0;
  width: auto;
}
.amcharts-chart-div {
  min-width: 100% !important;
}
