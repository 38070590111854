.userListFields {
  display: flex;
  flex-direction: row;
}

.userListFields > div {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: auto 0;
}

.userListSave .disabled {
  color: #CCC;
}