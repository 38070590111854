.App {
  height: 100%;
  width: 100%;
}

.welcomeText {
  margin: 1em 2em;
  max-width: 60%;
  text-align: center;
}

.alignCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.app .buttons .left {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.app .buttons .right {
  justify-content: flex-end;
  flex: 1;
  display: flex;
}

.app .buttons .actions {
  cursor: pointer;
  padding-top: 0.5em;
}

.app .buttons .disabled {
  color: #ccc;
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.app .productList {
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: row-reverse;
}

.app .productList .divider {
  flex: 1;
  max-width: 1px;
  background-color: rgb(224, 224, 224);
}

.app .productList .search {
  flex: 2;
}

.app .productList .selected {
  flex: 1;
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  overflow: hidden;
}

.app .productList .listHeader {
  font-size: 0.95em;
  color: rgba(0, 0, 0, 0.54);
  font-weight: 500;
  margin-left: 16px;
}

.app .loadingContent {
  height: 100ch;
}

.app .detailsButton {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}
.app .loadSaveButtons {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  margin-top: 1.5em;
}
.app .loadSaveButtons .actions {
  margin-bottom: 1em;
  cursor: pointer;
}
.app .loadSaveButtons .actions .disabled {
  color: #ccc;
  cursor: auto;
}
.app .clearButton {
  margin-top: 16px;
}
.app .mailing {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.MuiDrawer-paper {
  width: 25%;
}
