.storepriceplot {
  margin-top: 1em;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 1em;
}

.showStoreChartButton {
  margin: 1em !important;
  min-height: 20em;
}