.loading .animation {
  margin: 1em;
}

.loading {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}