.hoverBoxContainer {
  position: relative;
}

.hoverBoxContainer .popupText {
  position: absolute;
  padding: 0.7em;
  background-color: #FFF;
  margin-top: 1em;
  border: 1px solid #CCC;
  border-radius: 2px;
  font-size: 0.8em;
  box-shadow: 1px 3px 10px #CCC;
  z-index: 10;
}