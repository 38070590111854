/* .sortIcon {
  cursor: pointer;
  path: {
    fill: rgb(158, 158, 158) !important;
    pointer-events: none;
  }
} */

.tableHeader {
  width: auto;
  display: flex;
  align-items: center;
}
