.resultListContainer .noResults {
  margin: 1em 2em;
}

.resultListContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 20em;
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  background-color: white;
}

.resultListContainer .list {
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.resultListContainer .loadingResults {
  height: auto;
}