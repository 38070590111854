.panes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.mailingCategoryTree {
  overflow: scroll;
  height: 50vh;
}

.panes > div > h5 {
  margin: 0;
}

.mailingResultList {
  overflow: scroll;
  height: 50vh;
}

.panes > div {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.panes .methodInfo {
  font-size: 0.7em;
  padding: 1em;
}

.mailingSubHeader {
  text-transform: uppercase;
  color: rgb(210, 41, 13);
  flex: 1;
}
.subHeaders {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 1em;
}

.emailTextField {
  margin-right: 2em;
}