.userListOpenFields {
  display: flex;
  flex-direction: column;
}

.userListOpenFields > div {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: auto 0;
}

.userListOpenFields .methodInfo {
  font-size: 0.7em;
  padding: 1em;
}
.deleteList {
  margin-right: 3em;
}