.buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
}

.button {
  margin: auto;
}

.toggle {
  padding: 0.5em;
}

.toggleDiscountPrices {
  margin: 1em;
  width: auto !important;
  display: flex !important;
}
.toggleComparePrices {
  margin: 1em;
  width: auto !important;
  display: flex !important;
}

.loadingCharts {
  height: 100vh;
}

.radio {
  margin: 1em;
}

.flex {
  flex: 1;
}

.datepicker {
  margin: 1em;
  display: flex;
}

.datepicker > div {
  flex: 1;
}

.datepicker label {
  font-weight: bold;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54);
}

.datepicker input {
  padding: 1em;
  width: 6em;
}

.datepicker .popper {
  z-index: 10 !important;
}

.datepicker .datepickerEnd .popper .react-datepicker__triangle {
  right: 5em;
  left: initial;
}
